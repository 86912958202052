import React from "react"
import { graphql } from "gatsby"
import ArchiveLayout from "../components/archivelayout"
import SEO from "../components/seo"
import Sidebar from "../components/sidebar"
import { Container, Row, Col } from "reactstrap"
import Post from "../components/Post"

import styled from "styled-components"

const tagPosts = ({ data, pageContext }) => {
  const { tag } = pageContext
  const { totalCount } = data.allMdx
  const pageHeader = `${totalCount} "${tag}" Post${totalCount === 1 ? "" : "s"}`

  return (
    <ArchiveLayout pageTitle={`Page: ${pageHeader}`}>
      <SEO title={tag} keywords={[`chickens`]} />
      <Container>
        <Row>
          <Col sm="8">
            <TagTitle>{pageHeader}</TagTitle>
            {data.allMdx.edges.map(({ node }) => (
              <Post
                key={node.id}
                title={node.frontmatter.title}
                sizes={node.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
                date={node.frontmatter.data}
                body={node.frontmatter.description}
                slug={node.fields.slug}
                tags={node.frontmatter.tags}
              />
            ))}
          </Col>

          <Col sm="4">
            <Sidebar />
          </Col>
        </Row>
      </Container>
    </ArchiveLayout>
  );
}

export const tagQuery = graphql`query ($tag: String!) {
  allMdx(
    sort: {order: DESC, fields: [frontmatter___date]}
    filter: {frontmatter: {tags: {in: [$tag]}}}
  ) {
    totalCount
    edges {
      node {
        id
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          tags
          description
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 500, height: 280, quality: 80, layout: CONSTRAINED)
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
}
`
export default tagPosts

const TagTitle = styled.h1`
  color: #fff;
  font-family: "Jost";
  font-size: 2rem;
`
